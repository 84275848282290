import React, { useCallback, useState } from "react"
import { Helmet } from "react-helmet"

import { useLanguage, useWindowSize } from "../hooks"
import {
  ColumnContainer,
  Footer,
  PageContainer,
  Pdf,
  Scroll,
  Title,
  TopBar,
  TopMenu,
} from "../components"
import { withTranslationHOC } from "../i18n/with-translation"

function References() {
  const { t } = useLanguage()
  const [isLoaded, setLoaded] = useState(false)
  const { isMobile } = useWindowSize()

  const handleLoad = useCallback(() => setLoaded(true), [])

  return (
    <PageContainer>
      <Helmet>
        <title>{t("menu.references")}</title>
      </Helmet>

      {!isLoaded && <TopBar />}

      <Scroll>
        <ColumnContainer
          w="100vw"
          custom
          css={`
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1260px) {
              align-items: flex-start;
            } ;
          `}
        >
          <TopMenu>
            {!isMobile && <Title fontSize={24}>{t("menu.references")}</Title>}
          </TopMenu>

          <Pdf onLoad={handleLoad} />
        </ColumnContainer>
      </Scroll>

      <Footer status={isLoaded ? t("status.done") : t("status.refresh")} />
    </PageContainer>
  )
}

export default withTranslationHOC(References)
